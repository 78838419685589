import type { LinksFunction } from '@remix-run/cloudflare';

import latoNormal400Font2 from '@fontsource/lato/files/lato-all-400-normal.woff';
import latoNormal400Font3 from '@fontsource/lato/files/lato-latin-400-normal.woff2';
import latoNormal400Font1 from '@fontsource/lato/files/lato-latin-ext-400-normal.woff2';

import latoNormal700Font2 from '@fontsource/lato/files/lato-all-700-normal.woff';
import latoNormal700Font3 from '@fontsource/lato/files/lato-latin-700-normal.woff2';
import latoNormal700Font1 from '@fontsource/lato/files/lato-latin-ext-700-normal.woff2';

/* ../../node_modules/@fontsource/lato/400.css */
export const lato400Style = `
@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(${latoNormal400Font1}) format("woff2"), url(${latoNormal400Font2}) format("woff");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(${latoNormal400Font3}) format("woff2"), url(${latoNormal400Font2}) format("woff");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
`;

/* ../../node_modules/@fontsource/lato/700.css */
export const lato700Style = `
@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(${latoNormal700Font1}) format("woff2"), url(${latoNormal700Font2}) format("woff");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(${latoNormal700Font3}) format("woff2"), url(${latoNormal700Font2}) format("woff");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
`;

export const links: LinksFunction = () => {
  return [
  { href: latoNormal400Font1, mimetype: 'font/woff2' },
  { href: latoNormal400Font2, mimetype: 'font/woff' },
  { href: latoNormal400Font3, mimetype: 'font/woff2' },
  { href: latoNormal700Font1, mimetype: 'font/woff2' },
  { href: latoNormal700Font2, mimetype: 'font/woff' },
  { href: latoNormal700Font3, mimetype: 'font/woff' }].
  map((stack) => {
    return {
      rel: 'preload',
      as: 'font',
      href: stack.href,
      type: stack.mimetype,
      crossOrigin: 'anonymous'
    };
  });
};
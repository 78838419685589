import { Box, type BoxProps } from '@mui/material';
import WHITE_OUTLINED_LGH_LOGO from '~/life-assets/Logos/lgh-hollow-white-stroke.svg';

export function WhiteOutlinedLGHLogo(props: BoxProps) {
  const { sx, ...rest } = props;
  return (
    <Box
      sx={{
        width: '88px',
        height: '36px',
        backgroundImage: `url(${WHITE_OUTLINED_LGH_LOGO})`,
        backgroundRepeat: 'no-repeat',
        margin: 'auto 0',
        ...sx,
      }}
      {...rest}
    />
  );
}

export function VerticalDivider({
  color,
  width,
}: {
  color: 'white' | 'black';
  width: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="24"
      viewBox="0 0 1 24"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09985 0.000488281C1.376 0.000488281 1.59985 0.224346 1.59985 0.500488V23.5005C1.59985 23.7766 1.376 24.0005 1.09985 24.0005C0.823711 24.0005 0.599854 23.7766 0.599854 23.5005V0.500488C0.599854 0.224346 0.823711 0.000488281 1.09985 0.000488281Z"
        fill={color}
      />
    </svg>
  );
}

import { Box, type BoxProps } from '@mui/material';
import LGH_LOGO from '~/life-assets/Logos/Logo-Laerdal-Global-Health.svg';
import MOBILE_LGH_LOGO from '~/life-assets/Logos/lgh-logomark-color.svg';
export function LGHLogo(props: BoxProps) {
  const { sx, ...rest } = props;
  return (
    <Box
      sx={{
        width: {
          xs: '32px',
          md: '88px',
        },
        height: {
          xs: '32px',
          md: '48px',
        },
        backgroundImage: {
          xs: `url(${MOBILE_LGH_LOGO})`,
          md: `url(${LGH_LOGO})`,
        },
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        margin: 'auto 0',
        ...sx,
      }}
      {...rest}
    />
  );
}
//90.4px

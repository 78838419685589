import { type WorkerEnv } from '../environment.server';

export {
  getRumInit,
  rumServiceName,
  workerEnvToRumScriptUrl,
  workerEnvToRumSnippet,
} from './rum';

export const ddsource = 'cloudflare-tail-worker';
export type RumEnv = 'production' | 'uat' | 'development' | 'feature';

export function workerEnvToRumEnv(workerEnv?: WorkerEnv): RumEnv {
  if (workerEnv === 'prod') return 'production';
  if (workerEnv === 'uat') return 'uat';
  if (workerEnv === 'dev') return 'development';
  return 'feature';
}

// https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#intake-domains
const DD_RUM_INTAKE_ORIGINS = {
  US1: 'https://browser-intake-datadoghq.com',
  US3: 'https://browser-intake-us3-datadoghq.com',
  US5: 'https://browser-intake-us5-datadoghq.com',
  EU1: 'https://browser-intake-datadoghq.eu',
  'US1-FED': 'https://browser-intake-ddog-gov.com',
  AP1: 'https://browser-intake-ap1-datadoghq.com',
} as const;

export function workerEnvToIntakeOrigin(workerEnv?: WorkerEnv) {
  if (workerEnv === 'prod') return DD_RUM_INTAKE_ORIGINS.EU1;
  if (workerEnv === 'uat') return DD_RUM_INTAKE_ORIGINS.US3;
  if (workerEnv === 'dev') return DD_RUM_INTAKE_ORIGINS.US1;
  return DD_RUM_INTAKE_ORIGINS.US1;
}

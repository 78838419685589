import { Container, LinearProgress } from '@mui/material';
import {
  json,
  type ErrorResponse,
  type LinksFunction,
  type LoaderFunctionArgs,
  type SerializeFrom,
} from '@remix-run/cloudflare';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useNavigation,
  useRouteError,
  type ShouldRevalidateFunction,
} from '@remix-run/react';
import { devalue } from 'devalue';
import { Fragment, useState } from 'react';

import { COLORS } from '@tbd/life-tokens';

import {
  getRumInit,
  workerEnvToIntakeOrigin,
  workerEnvToRumScriptUrl,
  workerEnvToRumSnippet,
} from './datadog/index.ts';
import { LOG_SCOPE_DEFAULT, createLogger, enable } from './logging.ts';
import { MuiMeta } from './mui/MuiMeta.tsx';
import { MuiDocument } from './mui/MuiDocument.tsx';
import { AnonymousLayout } from './components/AnonymousLayout.tsx';
import {
  links as getFontLinks,
  lato400Style,
  lato700Style,
} from './fontFaceStyles.ts';
import {
  useOptionalRouteData as _useOptionalRouteData,
  useRouteData as _useRouteData,
} from './hooks/useMatchesData.ts';
import { getSystemIconDeferredLinks } from './components/SystemIconDeferred.tsx';

const ROUTE_ID = 'root' as const;
export type RouteLoaderType = typeof loader;
export type RouteLoaderData = SerializeFrom<RouteLoaderType>;
export const useRouteData = () => _useRouteData<RouteLoaderType>(ROUTE_ID);
export const useOptionalRouteData = () =>
  _useOptionalRouteData<RouteLoaderType>(ROUTE_ID);

export const links: LinksFunction = () => [
  ...getFontLinks(),
  ...getSystemIconDeferredLinks(),
];
export const loader = async ({ context }: LoaderFunctionArgs) => {
  const appLoadContext = context;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { simcapture, ...rootData } = appLoadContext;
  return json(rootData);
};

export default function App() {
  return (
    <Document>
      <Outlet />
    </Document>
  );
}

const globalCss = `
:root {
  /* --color-foreground: hsl(0, 0%, 7%);
  --color-background: hsl(0, 0%, 100%);
  --color-links: hsl(213, 100%, 52%);
  --color-links-hover: hsl(213, 100%, 43%);
  --color-border: hsl(0, 0%, 82%);
  --font-body: Lato, -apple-system, 'Segoe UI', Helvetica Neue, Helvetica, Roboto,
    Arial, sans-serif, system-ui, 'Apple Color Emoji', 'Segoe UI Emoji'; */
}

html {
  box-sizing: border-box;
  /* Available in @tbd/life-tokens/src/lib/typography.ts */
  font-family: Lato, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}
`;

export const shouldRevalidate: ShouldRevalidateFunction = ({ formAction }) => {
  const isAnyLogin = formAction?.startsWith('/login');
  const isAnyLogout = formAction?.startsWith('/logout');
  const shouldRevalidate = isAnyLogin || isAnyLogout;
  return Boolean(shouldRevalidate);
};

export function ErrorBoundary() {
  const logger = createLogger('root-error-boundary');
  const error = useRouteError();

  // this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return handleErrorResponse(error);
  } else if (error instanceof Error) {
    logger.error(error);
    return handleError(error);
  }
  logger.debug('unknown error', error);
  return handleError();
}

function handleError(error?: Error) {
  return (
    <Document title="Error!">
      <AnonymousLayout backgroundColor={COLORS.white}>
        <>
          <h1>An unexpected error occurred</h1>
          <p>
            It may work if you <a href="">try again</a>. Otherwise, go back to
            the <a href="/">home page</a>
          </p>
          {error?.stack ? (
            <>
              <hr />
              <small>
                <details>
                  <summary>error details</summary>
                  <pre>{error.stack}</pre>
                </details>
              </small>
            </>
          ) : null}
        </>
      </AnonymousLayout>
    </Document>
  );
}

function handleErrorResponse(response: ErrorResponse) {
  const error = new Error(response.data || response.statusText);
  let message;
  switch (response.status) {
    case 401:
      message = (
        <p>
          Oops! Looks like you are not logged in.{' '}
          <a href="/login">Try logging in again</a>
        </p>
      );
      break;
    case 404:
      message = (
        <>
          <p>Oops! Looks like you tried to visit a page that does not exist.</p>
          <p>
            Go to the <a href="/">home page</a>
          </p>
        </>
      );
      break;

    default:
      throw error;
  }

  return (
    <Document title={`${response.status} ${response.statusText}`}>
      <Container maxWidth="xxl">
        <h1>
          {response.status}: {response.statusText}
        </h1>
        {message}
      </Container>
    </Document>
  );
}

interface DocumentProps {
  children: React.ReactNode;
  title?: string;
}

function Document({ children, title }: DocumentProps) {
  // cannot `useLoaderData` in an error boundary, but `useMatches` works
  // https://github.com/remix-run/remix/blob/bdec5d453df6928b5b722825a2c5c800b7c33a94/packages/remix-react/components.tsx#L357
  const rootData = useOptionalRouteData();

  // LOG_SCOPE comes from the context and can change from request to request
  const incomingScope = rootData?.LOG_SCOPE || LOG_SCOPE_DEFAULT;
  const [logScope, setLogScope] = useState('');
  if (logScope !== incomingScope) {
    setLogScope(incomingScope);
    enable(incomingScope);
  }

  const navigation = useNavigation();
  const rumScriptUrl = workerEnvToRumScriptUrl(rootData?.WORKER_ENV);
  const bootstrapRumSnippet = workerEnvToRumSnippet(rootData?.WORKER_ENV);

  return (
    <MuiDocument>
      <html lang="en">
        <head>
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: [lato400Style, lato700Style].join('\n'),
            }}
          />
          {[
            workerEnvToIntakeOrigin(rootData?.WORKER_ENV),
            new URL(rumScriptUrl).origin,
          ].map((origin) => {
            return (
              <Fragment key={origin}>
                <link
                  rel="dns-prefetch"
                  href={origin}
                  crossOrigin="anonymous"
                />
                <link rel="preconnect" href={origin} crossOrigin="anonymous" />
              </Fragment>
            );
          })}
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          {title ? <title>{title}</title> : null}
          <Meta />
          <MuiMeta />
          <Links />
          <style
            type="text/css"
            dangerouslySetInnerHTML={{ __html: globalCss }}
          />
          <script
            async={true}
            dangerouslySetInnerHTML={{ __html: bootstrapRumSnippet }}
          ></script>
          <script
            async={true}
            src={rumScriptUrl}
            crossOrigin="anonymous"
          ></script>
        </head>
        <body>
          <LinearProgress
            title="Loading..."
            sx={{
              display: navigation.state === 'idle' ? 'none' : 'block',
              position: 'fixed',
              zIndex: 9999,
              top: 0,
              width: '100%',
            }}
          />
          {children}
          <ScrollRestoration />
          {rootData?.shouldLoadScripts ? <Scripts /> : null}
          <script
            async={true}
            dangerouslySetInnerHTML={{
              __html: getRumInit(rootData),
            }}
          ></script>
          <script
            async={true}
            dangerouslySetInnerHTML={{
              __html: `console.log(${devalue({
                clientSubdomain: rootData?.clientSubdomain,
                LIFT_VERSION: rootData?.LIFT_VERSION,
                manifestVersion: rootData?.manifestVersion,
              })})`,
            }}
          ></script>
        </body>
      </html>
    </MuiDocument>
  );
}

import { SvgIcon, type SvgIconProps } from '@mui/material';
import type { LinksFunction } from '@remix-run/cloudflare';
import spriteHref from '../life-assets/SystemIconSprite.svg';

export const getSystemIconDeferredLinks: LinksFunction = () => [
  {
    rel: 'preload',
    as: 'image',
    type: 'image/svg+xml',
    href: spriteHref,
  },
];

export function SystemIconDeferred({
  name,
  ...props
}: SvgIconProps & {
  name: SystemIconName;
}) {
  return (
    <SvgIcon {...props}>
      <svg data-testid={`${name}SystemIcon`}>
        <use href={`${spriteHref}#${name}`} />
      </svg>
    </SvgIcon>
  );
}

// TODO LIFT-784: generate this type while building the sprite
type SystemIconName =
  | 'Accessibility'
  | 'Add'
  | 'AddUser'
  | 'Adult'
  | 'AEDPads'
  | 'AEDPadsCorrect'
  | 'AEDPadsError'
  | 'Archive'
  | 'ArrowCollapse'
  | 'ArrowDropDown'
  | 'ArrowDropLeft'
  | 'ArrowDropRight'
  | 'ArrowDropUp'
  | 'ArrowExpand'
  | 'ArrowLineDown'
  | 'ArrowLineLeft'
  | 'ArrowLineRight'
  | 'ArrowLineUp'
  | 'ArrowStopLeft'
  | 'ArrowStopRight'
  | 'Assignments'
  | 'Asterisk'
  | 'Attachment'
  | 'Attention'
  | 'AudioDescriptionOn'
  | 'Backwards5Sec'
  | 'BackwardsFiveSec'
  | 'Battery0'
  | 'Battery1'
  | 'Battery2'
  | 'Battery3'
  | 'Battery4'
  | 'Battery5'
  | 'Battery6'
  | 'BatteryFull'
  | 'Bluetooth'
  | 'Book'
  | 'BookmarkAdd'
  | 'BookmarkCollection'
  | 'BookmarkOff'
  | 'BookmarkOn'
  | 'Bullet'
  | 'Calendar'
  | 'CheckboxOff'
  | 'CheckboxOn'
  | 'CheckboxSemi'
  | 'Checkmark'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'Child'
  | 'Clear'
  | 'Close'
  | 'ClosedCaptionOn'
  | 'Cloud'
  | 'CloudAttention'
  | 'CloudCheck'
  | 'CloudDownload'
  | 'CloudLocked'
  | 'CloudNoConnection'
  | 'CloudSyncing'
  | 'CloudUpload'
  | 'CoffeeBreak'
  | 'Copy'
  | 'Dashboard'
  | 'DecisionFlow'
  | 'Delete'
  | 'Discount'
  | 'Document'
  | 'Download'
  | 'DragHandle'
  | 'DragIndicator'
  | 'Edit'
  | 'Educator'
  | 'Equals'
  | 'EventLog'
  | 'Export'
  | 'ExportCSV'
  | 'ExportCVS'
  | 'ExportDOC'
  | 'ExportEXL'
  | 'ExportJPG'
  | 'ExportPDF'
  | 'ExportPNG'
  | 'ExportXLS'
  | 'ExtendTextArea'
  | 'Facebook'
  | 'Filter'
  | 'Flickr'
  | 'Forward'
  | 'Forwards5Sec'
  | 'ForwardsFiveSec'
  | 'ForwardSlash'
  | 'Fullscreen'
  | 'FullscreenExit'
  | 'GearSettings'
  | 'GridView'
  | 'Group'
  | 'GuidedTour'
  | 'HeartShock'
  | 'Help'
  | 'Hierarchy'
  | 'History'
  | 'Home'
  | 'Image'
  | 'Infant'
  | 'Information'
  | 'Institute'
  | 'Keyboard'
  | 'Language'
  | 'Learner'
  | 'Legend'
  | 'Legend1Circle'
  | 'Legend2Triangle'
  | 'Legend3Star'
  | 'Legend5Diamond'
  | 'Legend6Nabla'
  | 'Legend7Pentagon'
  | 'Legend8Rectangle'
  | 'LegendStrokeDashed'
  | 'LegendStrokeSolid'
  | 'LikeOff'
  | 'LikeOn'
  | 'Link'
  | 'LinkedIn'
  | 'ListView'
  | 'LockedOff'
  | 'LockedOn'
  | 'Login'
  | 'Logout'
  | 'Loop'
  | 'Mail'
  | 'Manikin'
  | 'MapPoint'
  | 'Menu'
  | 'Metronome'
  | 'Microphone'
  | 'Minus'
  | 'MoodHappy'
  | 'MoodIndifferent'
  | 'MoodSad'
  | 'MoodVeryHappy'
  | 'MoodVerySad'
  | 'MoreHorizontal'
  | 'MoreVertical'
  | 'Notification'
  | 'NotificationNew'
  | 'OpenFolder'
  | 'OpenNewWindow'
  | 'Overview'
  | 'Palette'
  | 'Pause'
  | 'PieChart'
  | 'Pin'
  | 'Play'
  | 'PlaybackSpeed'
  | 'Playlist'
  | 'PlayOutlined'
  | 'Plus'
  | 'PointDown'
  | 'PointLeft'
  | 'PointUp'
  | 'RadioButtonOff'
  | 'RadioButtonOn'
  | 'Record'
  | 'Refresh'
  | 'Replay'
  | 'Rewind'
  | 'Save'
  | 'Search'
  | 'SendTo'
  | 'Share'
  | 'Shock'
  | 'ShockAdvised'
  | 'ShockAutomated'
  | 'ShockNotAdvised'
  | 'ShoppingCart'
  | 'SkipForward'
  | 'Sort'
  | 'SpeechBubble'
  | 'Stack'
  | 'StarFilled'
  | 'StarOutlined'
  | 'Stop'
  | 'SUN'
  | 'Support'
  | 'SwitchApp'
  | 'Team'
  | 'TechnicalWarning'
  | 'ThumbsDown'
  | 'ThumbsUp'
  | 'Time'
  | 'TimeLimited'
  | 'Timeline'
  | 'Tip'
  | 'Transcript'
  | 'Translation'
  | 'Twitter'
  | 'TwitterX'
  | 'Upload'
  | 'Usb'
  | 'UserPerson'
  | 'Video'
  | 'VisibleOff'
  | 'VisibleOn'
  | 'VolumeDown'
  | 'VolumeOff'
  | 'VolumeUp'
  | 'YouTube'
  | 'ZoomIn'
  | 'ZoomOut';

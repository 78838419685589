import type {
  RumInitConfiguration,
  RumPublicApi,
} from '@datadog/browser-rum-core';
import { devalue } from 'devalue';

import { workerEnvToRumEnv } from './index';
import type { Environment } from '../environment.server';
import type { RouteLoaderData as RootData } from '../root';

export const rumServiceName = 'lift-remix';

// The Datadog RUM script adds a global `DD_RUM` with type `RumPublicApi`
declare global {
  interface Window {
    DD_RUM: RumPublicApi;
  }
}

export const workerEnvToRumSnippet = (
  workerEnv?: Environment['WORKER_ENV']
) => {
  const rumScriptUrl = workerEnvToRumScriptUrl(workerEnv);
  const rumSnippet = `
  // only the queueing from https://docs.datadoghq.com/real_user_monitoring/browser/#cdn-async
  (function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    // d=o.createElement(u);d.async=1;d.src=n
    // n=o.getElementsByTagName(u)[0];
    // n.parentNode.insertBefore(d,n)
  })(window,document,'script','${rumScriptUrl}','DD_RUM');
  `;
  return rumSnippet;
};

export function getRumInit(rootData?: RootData) {
  rootData == rootData || __remixContext?.state?.loaderData?.root;
  const rumConfig = getRumConfig(rootData || {});
  const rumInit = `
try {
  DD_RUM.onReady(function () {
    var config = ${devalue(rumConfig)};
    // can't stringify a function (and *NOTHING IN THIS FUNCTION USES DATA FROM THE SERVER OR USERS*) so we skip the escaping
    // use a plain function to support the most browsers (arrow functions will cause a SyntaxError and prevent capturing the RUM data)
    config.beforeSend = function beforeSend(event){
      // Current state (all Chromiums; no FF nor Safari): https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
      // Very old/early API: https://www.davidbcalhoun.com/2010/optimizing-based-on-connection-speed-using-navigator.connection-on-android-2.2-/
      var connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;

      if (connection) {
        // tried the 'event.context = {...event.context, connection}' approach
        // shown in https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#enrich-rum-events
        // and some others (Object.assign, etc) but for some reason 'connection' cannot be directly applied to context
        // so pull the individual properties off
        var { type, effectiveType, downlink, downlinkMax, rtt } = connection;

        // 'event.context.connection = { type, etc} ' worked locally, but sticking with the syntax shown in
        //  https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#enrich-rum-events
        event.context = {
          ...event.context,
          connection: {
            type,
            effectiveType,
            downlink,
            downlinkMax,
            rtt,
          },
        };
      }
    };
    DD_RUM.init(config);
  });
} catch (error) {
  console.error(error);
}
`;
  return rumInit;
}

const DD_PROD_TOKEN = 'pub929cd49ef4f7e852eb8364a4b565ecf6';
const DD_PROD_APPID = 'd2046061-9c3f-44af-a02c-0ae85d94be40';
const DD_DEV_TOKEN = 'pubeae9d9f66817d689a3b1d4bd09deb872';
const DD_DEV_APPID = '0f385b4f-e202-43e2-9dd1-59b90dbe9651';

function getRumConfig(params: RootData | Environment) {
  const isProd = params.WORKER_ENV === 'prod';
  const rumEnv = workerEnvToRumEnv(params.WORKER_ENV);

  const config: RumInitConfiguration = {
    site: 'datadoghq.com',
    service: rumServiceName,
    version: params.LIFT_VERSION,
    // *PROD* for 'prod' *DEV* for everything else (main, feature branches, uat, etc)
    clientToken: isProd ? DD_PROD_TOKEN : DD_DEV_TOKEN,
    applicationId: isProd ? DD_PROD_APPID : DD_DEV_APPID,
    enableExperimentalFeatures: ['clickmap'],
    env: rumEnv,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      // API_ORIGIN hosts
      /https?:\/\/.*\..*\.simcapture-dev\.com/,
      /https?:\/\/.*\..*\..*\.simcapture-dev\.com/,
      /https?:\/\/.*\..*\.simcapture-staging\.com/,
      /https?:\/\/.*\..*\..*\.simcapture-staging\.com/,
      /https?:\/\/.*\.simcapture\.com/,
      /https?:\/\/.*\..*\.simcapture\.com/,

      // local hosts
      /https?:\/\/127\.0\.0\.1:?\d+/,
      /https?:\/\/localhost:?\d+/,

      // cloudflare workers.dev hosts
      /https?:\/\/.*\.laerdal-labs\.workers\.dev/,
      /https?:\/\/.*\..*\..*\.laerdal-labs\.workers\.dev/,

      // public LIFT domains
      /https?:\/\/.*\.laerdal-lift-dev\.com/,
      /https?:\/\/.*\..*\..*\.laerdal-lift-dev\.com/,

      /https?:\/\/laerdal-lift-uat\.com/,
      /https?:\/\/.*\.laerdal-lift-uat\.com/,
      /https?:\/\/.*\..*\..*\.laerdal-lift-uat\.com/,

      /https?:\/\/laerdal-lift\.com/,
      /https?:\/\/.*\.laerdal-lift\.com/,
      /https?:\/\/.*\..*\..*\.laerdal-lift\.com/,
    ],
  };

  return config;
}

// https://docs.datadoghq.com/real_user_monitoring/browser/#cdn-async
const DD_RUM_SCRIPTS = {
  US1: 'https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js',
  US3: 'https://www.datadoghq-browser-agent.com/us3/v5/datadog-rum.js',
  US5: 'https://www.datadoghq-browser-agent.com/us5/v5/datadog-rum.js',
  EU1: 'https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js',
  'US1-FED': 'https://www.datadoghq-browser-agent.com/datadog-rum-v5.js',
  AP1: 'https://www.datadoghq-browser-agent.com/ap1/v5/datadog-rum.js',
} as const;

export function workerEnvToRumScriptUrl(workerEnv?: Environment['WORKER_ENV']) {
  if (workerEnv === 'prod') return DD_RUM_SCRIPTS.EU1;
  if (workerEnv === 'uat') return DD_RUM_SCRIPTS.US3;
  if (workerEnv === 'dev') return DD_RUM_SCRIPTS.US1;
  return DD_RUM_SCRIPTS.US1;
}

import {
  AppBar,
  type AppBarProps,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  ListItemText,
  MenuList,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

import { NavLink, useLocation } from '@remix-run/react';
import { COLORS } from '@tbd/life-tokens';
import { useRef } from 'react';
import { isAdminUser } from '~/auth';
import type { AuthenticatedUser } from '~/auth.server';
import { LogoTitleLockup } from '~/components/LogoTitleLockup';
import { ProfileMenu } from '~/components/ProfileMenu';
import { Close, Menu } from '~/components/SystemIconImmediate';

type PageTuple = [label: string, path: string];

const adminPages: Array<PageTuple> = [
  ['Sessions', '/sessions'],
  ['Users', '/users'],
];

export const BAR_HEIGHT = '64px';

interface GlobalNavigationBarProps {
  user?: AuthenticatedUser;
  sx?: AppBarProps['sx'];
  canAccessDashboard?: boolean;
}

export const GlobalNavigationBar = ({
  user,
  sx,
  canAccessDashboard = false,
}: GlobalNavigationBarProps) => {
  return (
    <AppBar
      position="sticky"
      sx={{
        height: BAR_HEIGHT,
        top: 0,
        ...(user ? { mb: { xs: 2, sm: 3 } } : {}),
        ...(sx || {}),
        backgroundColor: COLORS.white,
        borderBottom: `1px solid ${COLORS.neutral_200}`,
        boxShadow: 'none',
      }}
    >
      <Container maxWidth="xxl" disableGutters sx={{ paddingX: 1 }}>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            height: BAR_HEIGHT,
          }}
        >
          {user ? (
            <AuthenticatedNav
              user={user}
              canAccessDashboard={canAccessDashboard}
            />
          ) : (
            <AnonymousNav />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

function AnonymousNav() {
  return (
    <NavLink
      to="/login"
      style={() => {
        return {
          textDecoration: 'none',
        };
      }}
    >
      <LogoTitleLockup textColor="black" logoVariant="multi-colored" />
    </NavLink>
  );
}

function getNavLinks(options: { canAccessDashboard?: boolean }) {
  const navLinks = options.canAccessDashboard
    ? [...adminPages, ['Dashboard', '/dashboard']]
    : adminPages;

  return navLinks;
}

function AuthenticatedNav({
  user,
  canAccessDashboard = false,
}: {
  user: AuthenticatedUser;
  canAccessDashboard?: boolean;
}) {
  const isAdmin = isAdminUser(user);
  const navLinks = getNavLinks({ canAccessDashboard });

  return (
    <>
      <Stack direction={'row'}>
        <NavDrawerAndButton
          user={user}
          canAccessDashboard={canAccessDashboard}
        />
        <Stack direction={'row'} alignItems={'center'}>
          <NavLink
            to="/"
            style={() => {
              return {
                textDecoration: 'none',
              };
            }}
          >
            <LogoTitleLockup
              sx={{
                paddingInlineEnd: 3,
              }}
              textColor="black"
              logoVariant="multi-colored"
            />
          </NavLink>
        </Stack>
        <Box
          component="nav"
          sx={{
            height: BAR_HEIGHT,
            display: isAdmin ? { xs: 'none', md: 'flex' } : 'none',
            width: '100%',
            gap: '4px',
          }}
        >
          {isAdmin &&
            navLinks.map(([label, path]) => (
              <TopBarNavLink key={label} path={path} label={label} />
            ))}
        </Box>
      </Stack>
      <ProfileMenu user={user} triggerVariant="light-green-circle-no-outline" />
    </>
  );
}

function NavDrawerAndButton({
  user,
  canAccessDashboard = false,
}: {
  user: AuthenticatedUser;
  canAccessDashboard?: boolean;
}) {
  const isAdmin = isAdminUser(user);
  const navLinks = getNavLinks({ canAccessDashboard });
  const location = useLocation();

  const menuRef = useRef<HTMLElement | null>(null);

  const closeMenu = () => {
    const menuElement = menuRef.current;
    if (menuElement) {
      menuElement.removeAttribute('open');
    }
  };

  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
      }}
    >
      {isAdmin && (
        <Box
          component="details"
          ref={menuRef}
          sx={{
            '&[open] > summary::before': {
              content: "' '",
              display: 'block',
              position: 'fixed',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            },
            '&[open] > summary > .close-icon': {
              display: 'block',
            },
            '&[open] > summary': {
              display: 'block',
              backgroundColor: 'white',
              color: COLORS.accent1,
              border: 0,
              borderRadius: 0,
            },
            '&[open] > summary > .menu-icon': {
              display: 'none',
            },
            '& > summary > .close-icon': {
              display: 'none',
            },
          }}
        >
          <IconButton
            component="summary"
            size="large"
            aria-label="Navigation menu"
            aria-controls="menu-navigation"
            aria-haspopup="true"
            sx={{
              border: 0,
              '&::before': { display: 'none' },
              '&::-webkit-details-marker': { display: 'none' },
            }}
          >
            <Menu className="menu-icon" />
            <Close className="close-icon" />
          </IconButton>
          <Stack
            component="nav"
            sx={{
              position: 'absolute',
              background: 'white',
              width: 'calc(100% + 16px)',
              margin: '0 -8px',
              top: '64px',
              maxWidth: '360px',
              boxShadow: 'unset',
              bgcolor: 'background.paper',
              '&::before': {
                content: '""',
                position: 'fixed',
                top: '64px',
                left: 0,
                width: '100%',
                height: 'calc(100% - 64px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: -1,
              },
            }}
          >
            <Typography
              variant="component-bold-s"
              component="h1"
              noWrap
              sx={{ padding: '16px', color: COLORS.neutral_600 }}
            >
              Site Menu
            </Typography>
            <Divider variant="middle" />
            <MenuList>
              {navLinks.map(([label, path]) => (
                <Box display="flex" flexDirection="row" key={label}>
                  <Button
                    fullWidth
                    component={NavLink}
                    key={path}
                    to={path}
                    onClick={closeMenu}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor:
                        location.pathname === path
                          ? COLORS.neutral_20
                          : 'inherit',
                    }}
                  >
                    {location.pathname === path ? (
                      <Divider
                        variant="middle"
                        orientation="vertical"
                        sx={{
                          borderRadius: '4px',
                          background: COLORS.accent1,
                          width: '4px',
                          marginRight: '8px',
                        }}
                      />
                    ) : (
                      <Box sx={{ width: '4px', marginRight: '8px' }} />
                    )}
                    <ListItemText primary={label} />
                  </Button>
                </Box>
              ))}
            </MenuList>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

function TopBarNavLink(props: { path: string; label: string }) {
  return (
    <Button
      key={props.path}
      component={NavLink}
      to={props.path}
      sx={{
        display: 'block',
        height: BAR_HEIGHT,
        lineHeight: '32px',
        margin: 0,
        p: 2,
        borderRadius: 0,
        borderBottom: '1px solid transparent',
        '&:hover': {
          background: COLORS.accent1_50,
          borderBottom: `1px solid ${COLORS.neutral_200}`,
        },
        '&:focus': {
          background: COLORS.accent1_50,
        },
        '&:active, &.active': {
          background: COLORS.accent1_100,
          borderBottom: `4px solid ${COLORS.accent1_600}`,
          '& .MuiTypography-root': {
            fontWeight: 700,
            color: COLORS.accent1_600,
          },
        },
      }}
    >
      <Typography sx={{ color: COLORS.neutral_500 }} variant="paragraph-s">
        {props.label}
      </Typography>
    </Button>
  );
}
export default GlobalNavigationBar;
